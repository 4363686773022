<template>
  <div class="container-about">
   <div><Navbar :values="article" /></div>

   <div class="container-acerca">
    <div class="container container-fade">  
      
      <div class="row acerca-page justify-content-center">
        <div class="col-md-8 col-12">
          <h1 class="text-center">{{ article.story.acercade.title }}</h1>
        </div>
        <!-- <div class="col-md-12 col-lg-9 col-12 position-relative">
          <div class="inline-video-container">
            <VideoInline video-uniq-id="univisionarios-video" :src="article.story.acercade.mcpid" />
          </div>
        </div> -->
        <div class="col-md-7 col-12">
          <div class="row articulo">
            <div class="col-12">
              <div v-html="article.story.acercade.content"></div>
            </div>

          </div>
        </div>
      </div>        
    </div>      
  </div>
    <Footer />
  </div>
</template>


<style>
  .inline-video-container {
    position: relative;
    width: 100%;
  }
</style>

<script>

import Navbar from '@/components/Navbar'
import Footer from '@/pages/Footer'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    window.FMG.init({
      loglevel: 'prod',
      skin: {
        name: 'webapp jw-skin-default jw-skin-uvn'
      },
      connection: {
        env: 'prod',
      }
    })

    document.getElementById(`acerca-nav`).classList.add('active')
  },
  computed: {
    article() {
      return require(`@/i18n/es.json`)
    }
  }
}
</script>


